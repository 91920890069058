/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition*/
/* eslint-disable */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { useOpenContactModal, useViewport } from '../../hooks';
import techStyles from '../../pages/styles/newTechnology.module.scss';
import ogimage from '../../images/about/about-hero.jpg';
import ogimagemobile from '../../images/about/about-mobile-hero.jpg';
import aboutStyles from '../../pages/styles/about.module.scss';
import witbeWorldwide from '../../images/about/witbe-worldwide.svg';
import genesis from '../../images/about/genesis.jpg';
import investors from '../../images/about/our-investors.jpg';
import privacy from '../../images/about/privacy-policy.svg';
import careers from '../../images/about/careers.jpg'
import latest from '../../images/about/latest-updates.jpg';
import contactUsPic from '../../images/about/contact-us.svg';
import careerStyles from '../../pages/styles/newCareers.module.scss';
import { MODAL_SOURCE_BUTTONS } from '../../constants/modal';

const { ABOUT_COMPANY } = MODAL_SOURCE_BUTTONS;

export default function DesktopCompany() {
    const { t } = useTranslation();
    const openContactModal = useOpenContactModal(ABOUT_COMPANY);

    const { width } = useViewport();
    const breakpointTablets = 821;

    return (
        <>
            <div id={techStyles.mainImageWrapper}>
                {width < breakpointTablets ?
                <div className={techStyles.mainImageTextWrapper}>
                    <div className={techStyles.mainImageLeft}>
                        <div className={techStyles.mainImageContentMobile}>
                            <h1 className={techStyles.mainImageTitle}>{t('siteMetadata.aboutPage.title')}</h1>
                            <p className={techStyles.mainImageSub}>{t('siteMetadata.aboutPage.description')}</p>
                        </div>
                    </div>
                    <div className={techStyles.mainImageRight}>
                        <div className={techStyles.rightImageWrapper}>
                            <img src={ogimagemobile} alt='Banner' />
                        </div>
                    </div>
                </div>
                :
                    <div className={techStyles.mainImageTextWrapper}>
                        <img src={ogimage} id={techStyles.mainImg} alt='Banner' />
                        <div className={aboutStyles.mainImageContent}>
                            <h1 className={techStyles.mainImageTitle}>{t('siteMetadata.aboutPage.title')}</h1>
                            <p className={techStyles.mainImageSub}>{t('siteMetadata.aboutPage.description')}</p>
                        </div>
                    </div>
                }
            </div>
            <section id={aboutStyles.whiteSection}>
                <h2 id={techStyles.whiteSectionTitle}>
                    {t(`siteMetadata.aboutPage.story`)}
                </h2>
                <div className={techStyles.whiteSectionText}>
                    <p>{t(`siteMetadata.aboutPage.storySub`)}</p>
                </div>
            </section>
            {width < breakpointTablets ?
                (
                    <section id={techStyles.quadColSection}>
                        <ul id={techStyles.quadColList}>
                            <Link
                                to='/locations/'
                            >
                                <li className={techStyles.item}>
                                    <div className={techStyles.itemThumb}>
                                        <img src={witbeWorldwide} className={techStyles.itemIcon} alt='Witbe Worldwide' />
                                    </div>
                                    <div className={techStyles.itemText}>
                                        <h3 className={techStyles.itemTitle}>{t('siteMetadata.aboutPage.witbeWorldwide')}</h3>
                                        <p className={techStyles.itemParagraph}>{t('siteMetadata.aboutPage.witbeWorldwideSub')}</p>
                                    </div>
                                </li>
                            </Link>
                            <li className={techStyles.item}>
                                <div className={techStyles.itemThumb}>
                                    <img src={genesis} className={techStyles.itemIcon} alt='Leadership' />
                                </div>
                                <div className={techStyles.itemText}>
                                    <h3 className={techStyles.itemTitle}>
                                        {t('siteMetadata.aboutPage.leadership')}
                                    </h3>
                                    <p className={techStyles.itemParagraph}>{t('siteMetadata.aboutPage.leadershipSub')}</p>
                                </div>
                            </li>
                            <a target='_blank' href='http://alwit.net/' rel='noopener noreferrer'>
                                <li className={techStyles.item}>
                                    <div className={techStyles.itemThumb}>
                                        <img src={investors} className={techStyles.itemIcon} alt='Investor center' />
                                    </div>
                                    <div className={techStyles.itemText}>
                                        <h3 className={techStyles.itemTitle}>
                                            {t('siteMetadata.aboutPage.investors')}
                                        </h3>
                                        <p className={techStyles.itemParagraph}>{t('siteMetadata.aboutPage.investorsSub')}</p>
                                        <p className={techStyles.itemLearnMore}>
                                            {t('siteMetadata.aboutPage.learnMore')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={techStyles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </a>
                            <Link
                                to='/policy/'
                            >
                                <li className={techStyles.item}>
                                    <div className={techStyles.itemThumb}>
                                        <img src={privacy} className={techStyles.itemIcon} alt='Privacy policy' />
                                    </div>
                                    <div className={techStyles.itemText}>
                                        <h3 className={techStyles.itemTitle}>
                                            {t('siteMetadata.aboutPage.privacy')}
                                        </h3>
                                        <p className={techStyles.itemParagraph}>{t('siteMetadata.aboutPage.privacySub')}</p>
                                        <p className={techStyles.itemLearnMore}>
                                            {t('siteMetadata.aboutPage.learnMore')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={techStyles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link
                                    to='/careers/'
                                >
                                    <li className={techStyles.item}>
                                        <div className={techStyles.itemThumb}>
                                            <img src={careers} className={techStyles.itemIcon} alt='Careers' />
                                        </div>
                                        <div className={techStyles.itemText}>
                                            <h3 className={techStyles.itemTitle}>{t('siteMetadata.aboutPage.careers')}</h3>
                                            <p className={techStyles.itemParagraph}>{t('siteMetadata.aboutPage.careersSub')}</p>
                                            <p className={techStyles.itemLearnMore}>
                                                {t('siteMetadata.aboutPage.learnMore')}
                                                <FontAwesomeIcon
                                                    icon={faChevronRight}
                                                    className={techStyles.chevronRightBlue}
                                                />
                                            </p>
                                        </div>
                                    </li>
                            </Link>
                            <Link
                                to='/articles/'
                            >
                                    <li className={techStyles.item}>
                                        <div className={techStyles.itemThumb}>
                                            <img src={latest} className={techStyles.itemIcon} alt='Updates' />
                                        </div>
                                        <div className={techStyles.itemText}>
                                            <h3 className={techStyles.itemTitle}>
                                                {t('siteMetadata.aboutPage.updates')}
                                            </h3>
                                            <p className={techStyles.itemParagraph}>{t('siteMetadata.aboutPage.updatesSub')}</p>
                                            <p className={techStyles.itemLearnMore}>
                                                {t('siteMetadata.aboutPage.readAbout')}
                                                <FontAwesomeIcon
                                                    icon={faChevronRight}
                                                    className={techStyles.chevronRightBlue}
                                                />
                                            </p>
                                        </div>
                                    </li>
                            </Link>
                            <li className={techStyles.item} onClick={openContactModal}>
                                <div className={techStyles.itemThumb}>
                                    <img src={contactUsPic} className={techStyles.itemIcon} alt='Contact us' />
                                </div>
                                <div className={techStyles.itemText}>
                                    <h3 className={techStyles.itemTitle}>
                                        {t('siteMetadata.aboutPage.contact')}
                                    </h3>
                                    <p className={techStyles.itemParagraph}>{t('siteMetadata.aboutPage.contactSub')}</p>
                                    <p className={techStyles.itemLearnMore}>
                                        {t('siteMetadata.aboutPage.getInTouch')}
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            className={techStyles.chevronRightBlue}
                                        />
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </section>
                )
                :
                (
                    <>
                    <section id={techStyles.quadColSection}>
                        <ul id={techStyles.quadColList}>
                            <Link
                                    to='/locations/'
                                >
                                <li className={techStyles.item}>
                                    <div className={techStyles.itemThumb}>
                                        <img src={witbeWorldwide} className={techStyles.itemIcon} alt='Witbe Worldwide' />
                                    </div>
                                    <div className={techStyles.itemText}>
                                        <h3 className={techStyles.itemTitle}>{t('siteMetadata.aboutPage.witbeWorldwide')}</h3>
                                        <p className={techStyles.itemParagraph}>{t('siteMetadata.aboutPage.witbeWorldwideSub')}</p>
                                        <p className={techStyles.itemLearnMore}>
                                            {t('siteMetadata.aboutPage.learnMore')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={techStyles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <li className={careerStyles.itemNoHover}>
                                <div className={careerStyles.itemNoHoverThumb}>
                                    <img src={genesis} className={careerStyles.itemNoHoverIcon} alt='Leadership' />
                                </div>
                                <div className={careerStyles.itemNoHoverText}>
                                    <h3 className={careerStyles.itemNoHoverTitle}>
                                        {t('siteMetadata.aboutPage.leadership')}
                                    </h3>
                                    <p className={careerStyles.itemNoHoverParagraph}>{t('siteMetadata.aboutPage.leadershipSub')}</p>
                                </div>
                            </li>
                            <a target='_blank' href='http://alwit.net/' rel='noopener noreferrer'>
                                <li className={techStyles.item}>
                                    <div className={techStyles.itemThumb}>
                                        <img src={investors} className={techStyles.itemIcon} alt='Investor center' />
                                    </div>
                                    <div className={techStyles.itemText}>
                                        <h3 className={techStyles.itemTitle}>
                                            {t('siteMetadata.aboutPage.investors')}
                                        </h3>
                                        <p className={techStyles.itemParagraph}>{t('siteMetadata.aboutPage.investorsSub')}</p>
                                        <p className={techStyles.itemLearnMore}>
                                            {t('siteMetadata.aboutPage.learnMore')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={techStyles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </a>
                            <Link
                                to='/policy/'
                            >
                                <li className={techStyles.item}>
                                    <div className={techStyles.itemThumb}>
                                        <img src={privacy} className={techStyles.itemIcon} alt='Privacy policy' />
                                    </div>
                                    <div className={techStyles.itemText}>
                                        <h3 className={techStyles.itemTitle}>
                                            {t('siteMetadata.aboutPage.privacy')}
                                        </h3>
                                        <p className={techStyles.itemParagraph}>{t('siteMetadata.aboutPage.privacySub')}</p>
                                        <p className={techStyles.itemLearnMore}>
                                            {t('siteMetadata.aboutPage.learnMore')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={techStyles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                        </ul>
                    </section>
                    <section id={techStyles.quadColSection}>
                        <ul id={techStyles.quadColList}>
                            <Link
                                to='/careers/'
                            >
                                <li className={techStyles.item}>
                                    <div className={techStyles.itemThumb}>
                                        <img src={careers} className={techStyles.itemIcon} alt='Careers' />
                                    </div>
                                    <div className={techStyles.itemText}>
                                        <h3 className={techStyles.itemTitle}>{t('siteMetadata.aboutPage.careers')}</h3>
                                        <p className={techStyles.itemParagraph}>{t('siteMetadata.aboutPage.careersSub')}</p>
                                        <p className={techStyles.itemLearnMore}>
                                            {t('siteMetadata.aboutPage.learnMore')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={techStyles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <Link
                                to='/articles/'
                            >
                                <li className={techStyles.item}>
                                    <div className={techStyles.itemThumb}>
                                        <img src={latest} className={techStyles.itemIcon} alt='Updates' />
                                    </div>
                                    <div className={techStyles.itemText}>
                                        <h3 className={techStyles.itemTitle}>
                                            {t('siteMetadata.aboutPage.updates')}
                                        </h3>
                                        <p className={techStyles.itemParagraph}>{t('siteMetadata.aboutPage.updatesSub')}</p>
                                        <p className={techStyles.itemLearnMore}>
                                            {t('siteMetadata.aboutPage.readAbout')}
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                                className={techStyles.chevronRightBlue}
                                            />
                                        </p>
                                    </div>
                                </li>
                            </Link>
                            <li className={techStyles.item} onClick={openContactModal}>
                                <div className={techStyles.itemThumb}>
                                    <img src={contactUsPic} className={techStyles.itemIcon} alt='Contact us' />
                                </div>
                                <div className={techStyles.itemText}>
                                    <h3 className={techStyles.itemTitle}>
                                        {t('siteMetadata.aboutPage.contact')}
                                    </h3>
                                    <p className={techStyles.itemParagraph}>{t('siteMetadata.aboutPage.contactSub')}</p>
                                    <p className={techStyles.itemLearnMore}>
                                        {t('siteMetadata.aboutPage.getInTouch')}
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            className={techStyles.chevronRightBlue}
                                        />
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </section>
                    </>
                )
            }
        </>
    );
}
