/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition*/
/* eslint-disable */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import styles from './styles/mobileCompany.module.scss';
import { useOpenContactModal } from '../../hooks';
import ogimagemobile from '../../images/about/about-mobile-hero.jpg';
import techStyles from '../../pages/styles/newTechnology.module.scss';
import witbeWorldwide from '../../images/about/witbe-worldwide.svg';
import genesis from '../../images/about/genesis.jpg';
import investors from '../../images/about/our-investors.jpg';
import privacy from '../../images/about/privacy-policy.svg';
import careers from '../../images/about/careers.jpg'
import latest from '../../images/about/latest-updates.jpg';
import contactUsPic from '../../images/about/contact-us.svg';
import { MODAL_SOURCE_BUTTONS } from '../../constants/modal';

const { ABOUT_COMPANY } = MODAL_SOURCE_BUTTONS;

export default function MobileCompany() {
    const { t } = useTranslation()

    const openContactModal = useOpenContactModal(ABOUT_COMPANY);

    return (
        <>
            <div id={techStyles.mainImageWrapper}>
                <div className={techStyles.mainImageTextWrapper}>
                    <div className={techStyles.mainImageLeft}>
                        <div className={techStyles.mainImageContentMobile}>
                            <h1 className={techStyles.mainImageTitle}>{t('siteMetadata.aboutPage.title')}</h1>
                            <p className={techStyles.mainImageSub}>{t('siteMetadata.aboutPage.description')}</p>
                        </div>
                    </div>
                    <div className={techStyles.mainImageRight}>
                        <div className={techStyles.rightImageWrapper}>
                            <img src={ogimagemobile} alt='Banner' />
                        </div>
                    </div>
                </div>
            </div>
            <section id={techStyles.whiteSection}>
                <h2 id={techStyles.whiteSectionTitle}>
                    {t(`siteMetadata.aboutPage.story`)}
                </h2>
                <div className={techStyles.whiteSectionText}>
                    <p>{t(`siteMetadata.aboutPage.storySub`)}</p>
                </div>
            </section>
            <section id={techStyles.quadColSectionMobile}>
                <ul id={techStyles.quadColListMobile}>
                    <Link
                        to='/locations/'
                    >
                        <li
                            className={styles.itemMobile}
                        >
                            <img src={witbeWorldwide} alt='Witbe worldwide' />
                            <div className={styles.itemContentMobile}>
                                <h3 className={styles.itemTitleMobile}>{t('siteMetadata.aboutPage.witbeWorldwide')}</h3>
                                <p className={styles.itemParagraphMobile}>{t('siteMetadata.aboutPage.witbeWorldwideSub')}</p>
                                <p className={styles.itemLearnMobile}>
                                    {t('siteMetadata.aboutPage.learnMore')}
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={techStyles.chevronRightBlue}
                                    />
                                </p>
                            </div>
                        </li>
                    </Link>
                    <li
                        className={styles.itemMobile}
                    >
                        <img src={genesis} alt='Witbe leadership' />
                        <div className={styles.itemContentMobile}>
                            <h3 className={styles.itemTitleMobile}>{t('siteMetadata.aboutPage.leadership')}</h3>
                            <p className={styles.itemParagraphMobile}>{t('siteMetadata.aboutPage.leadershipSub')}</p>
                        </div>
                    </li>
                    <a target='_blank' href='http://alwit.net/' rel='noopener noreferrer'>
                        <li
                            className={styles.itemMobile}
                        >
                            <img src={investors} alt='Investors' />
                            <div className={styles.itemContentMobile}>
                                <h3 className={styles.itemTitleMobile}>{t('siteMetadata.aboutPage.investors')}</h3>
                                <p className={styles.itemParagraphMobile}>{t('siteMetadata.aboutPage.investorsSub')}</p>
                                <p className={styles.itemLearnMobile}>
                                    {t('siteMetadata.aboutPage.learnMore')}
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={techStyles.chevronRightBlue}
                                    />
                                </p>
                            </div>
                        </li>
                    </a>
                    <Link
                        to='/policy/'
                    >
                        <li
                            className={styles.itemMobile}
                        >
                            <img src={privacy} alt='Privacy policy' />
                            <div className={styles.itemContentMobile}>
                                <h3 className={styles.itemTitleMobile}>{t('siteMetadata.aboutPage.privacy')}</h3>
                                <p className={styles.itemParagraphMobile}>{t('siteMetadata.aboutPage.privacySub')}</p>
                                <p className={styles.itemLearnMobile}>
                                    {t('siteMetadata.aboutPage.learnMore')}
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={techStyles.chevronRightBlue}
                                    />
                                </p>
                            </div>
                        </li>
                    </Link>
                    <Link
                        to='/careers/'
                    >
                        <li
                            className={styles.itemMobile}
                        >
                            <img src={careers} alt='Careers' />
                            <div className={styles.itemContentMobile}>
                                <h3 className={styles.itemTitleMobile}>{t('siteMetadata.aboutPage.careers')}</h3>
                                <p className={styles.itemParagraphMobile}>{t('siteMetadata.aboutPage.careersSub')}</p>
                                <p className={styles.itemLearnMobile}>
                                    {t('siteMetadata.aboutPage.learnMore')}
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={techStyles.chevronRightBlue}
                                    />
                                </p>
                            </div>
                        </li>
                    </Link>
                    <Link
                        to='/articles/'
                    >
                        <li
                            className={styles.itemMobile}
                        >
                            <img src={latest} alt='Latest articles' />
                            <div className={styles.itemContentMobile}>
                                <h3 className={styles.itemTitleMobile}>{t('siteMetadata.aboutPage.updates')}</h3>
                                <p className={styles.itemParagraphMobile}>{t('siteMetadata.aboutPage.updatesSub')}</p>
                                <p className={styles.itemLearnMobile}>
                                    {t('siteMetadata.aboutPage.readAbout')}
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={techStyles.chevronRightBlue}
                                    />
                                </p>
                            </div>
                        </li>
                    </Link>
                    <Link
                        to='#'
                        onClick={openContactModal}
                    >
                        <li
                            className={styles.itemMobile}
                        >
                            <img src={contactUsPic} alt='Contact us' />
                            <div className={styles.itemContentMobile}>
                                <h3 className={styles.itemTitleMobile}>{t('siteMetadata.aboutPage.contact')}</h3>
                                <p className={styles.itemParagraphMobile}>{t('siteMetadata.aboutPage.contactSub')}</p>
                                <p className={styles.itemLearnMobile}>
                                    {t('siteMetadata.aboutPage.getInTouch')}
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={techStyles.chevronRightBlue}
                                    />
                                </p>
                            </div>
                        </li>
                    </Link>
                </ul>
            </section>
        </>
    );
}
