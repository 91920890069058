/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components/common';
import Customers from '../components/common/Customers';
import MobileCompany from '../components/common/MobileCompany';
import ogimage from '../images/company/company_main.jpg';
import SEO from '../components/common/SEO';
import { useViewport } from '../hooks';
import DesktopCompany from '../components/common/DesktopCompany';

export default function About() {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='About - Witbe'>
            <SEO
                title='About - Witbe'
                ogDescription='With more than 20 years of experience, Witbe is an international company with offices across the globe with a unique user-centric Quality of experience focus'
                description='With more than 20 years of experience, Witbe is an international company with offices across the globe with a unique user-centric Quality of experience focus'
                image={ogimage}
            />

            {width < breakpoint ? <MobileCompany /> : <DesktopCompany />}

            <Customers />
        </Layout>
    );
}
